//update with your firebase config and create firebase.config.js
module.exports = {
  apiKey: "AIzaSyB1-OcRPSDbZ8sQixQwRDD10VfbMtXQRcE",
  authDomain: "halloween-party-33c7e.firebaseapp.com",
  databaseURL: "https://halloween-party-33c7e.firebaseio.com",
  projectId: "halloween-party-33c7e",
  storageBucket: "halloween-party-33c7e.appspot.com",
  messagingSenderId: "992034181992",
  appId: "1:992034181992:web:f2e926f6879073e2bcfcdd",
  measurementId: "G-9LKGG2RBW5"
};